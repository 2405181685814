import React, { useCallback, useState } from 'react';
import config from '../../config';
import SelectorButton from './SelectorButton';
export default function Footer() {
  const [isOxfordSelected, setOxfordSelected] = useState(true);
  const [isMaternalSelected, setMaternalSelected] = useState(false);

  const handleOxfordSelectionPress = useCallback(() => {
    setOxfordSelected(true);
    setMaternalSelected(false);
  }, []);

  const handleMaternalSelectionPress = useCallback(() => {
    setMaternalSelected(true);
    setOxfordSelected(false);
  }, []);

  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">{`Contacto ${
          isOxfordSelected ? `Academia de Idiomas` : `Jardín Maternal`
        }`}</h2>
        <p>Deje su consulta y nos comunicaremos a la brevedad</p>
        <div className="buttonContainer">
          <SelectorButton
            label="Academia de Idiomas"
            isSelected={isOxfordSelected}
            onSelectionPress={handleOxfordSelectionPress}
          />
          <SelectorButton
            label="Jardín Maternal"
            isSelected={isMaternalSelected}
            onSelectionPress={handleMaternalSelectionPress}
          />
        </div>
        {isOxfordSelected && (
          <>
            <form method="post" action="https://formspree.io/mnqpwwbj">
              <div className="fields">
                <div className="field">
                  <label htmlFor="name">Nombre completo</label>
                  <input type="text" name="name" id="name" />
                </div>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="_replyto" id="email" />
                </div>
                <div className="field">
                  <label htmlFor="message">Mensaje</label>
                  <textarea name="message" id="message" rows="3"></textarea>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input className="msj" type="submit" value="enviar mensaje" />
                </li>
              </ul>
            </form>
            <ul className="contact">
              <li className="fa-home">{config.address}</li>

              <li className="fa-phone">{config.phone}</li>

              {config.socialLinks.map(social => {
                const { icon, name, url } = social;
                return (
                  <li className={`${icon}`} key={url}>
                    <a href={url}>{name}</a>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {isMaternalSelected && (
          <>
            <form method="post" action="https://formspree.io/f/xyybdwkl">
              <div className="fields">
                <div className="field">
                  <label htmlFor="name">Nombre completo</label>
                  <input type="text" name="name" id="name" />
                </div>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="_replyto" id="email" />
                </div>
                <div className="field">
                  <label htmlFor="message">Mensaje</label>
                  <textarea name="message" id="message" rows="3"></textarea>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input className="msj" type="submit" value="enviar mensaje" />
                </li>
              </ul>
            </form>
            <ul className="contact">
              <li className="fa-home">{config.maternalGarden.address}</li>

              <li className="fa-phone">{config.maternalGarden.phone}</li>

              {config.maternalGarden.socialLinks.map(social => {
                const { icon, name, url } = social;
                return (
                  <li className={`${icon}`} key={url}>
                    <a href={url}>{name}</a>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </section>
  );
}

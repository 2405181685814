import React, { Fragment } from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import nosotros from '../assets/images/nosotros.jpg';
import separadorBaby from '../assets/images/separador-baby.png';
import nosotrosBackground from '../assets/images/nosotrosBackground.jpg';
import nosotrosBaby1 from '../assets/images/nosotros-baby-1.jpg';
import nosotrosBaby2 from '../assets/images/nosotros-baby-2.jpg';
import about from '../assets/images/about-oxford.jpg';
import Banner from '../components/Banner';
import DrawerButton from '../components/DrawerButton';
import { babyGrupos } from '../assets/texts/babyGrupos';
import BlueTitleBox from '../components/BlueTitleBox';

export default function Nosotros() {
  return (
    <Fragment>
      <Layout>
        <Banner
          title="Sobre nosotros"
          subtitle="Academia líder en la enseñanza de inglés"
          backgroundImage={`url("${nosotrosBackground}")`}
        />
        <div className="somosContenido">
          <div className="contenidoContainer">
            <img className="img1" src={about} alt="" />
            <p className="about1">
              Bienvenidos a Oxford House, somos una academia líder en la
              enseñanza de inglés y especialistas en la enseñanza para niños
              desde los dos años. Ofrecemos también cursos muy dinámicos,
              interactivos y de una excelente calidad para adolescentes y
              adultos. Además de clases personalizadas en grupos reducidos.
              <br></br>
              <br></br>
              Nuestro objetivo es asegurarnos de que todos nuestros estudiantes
              mejoren su inglés y otros idiomas y alcancen sus metas de
              aprendizaje. Proporcionamos un plan de estudios personalizado y
              brindamos un apoyo y atención individualizada durante todo el
              curso para garantizar el progreso rápidamente y que logren sus
              objetivos de aprendizaje.
              <br></br>
              <br></br>
              Somos miembros de SEA, asociación de centros de idiomas, quienes
              avalan y respaldan nuestros desempeño en nuestra institución.
              <br></br>
              <br></br>
              Desde el 2017 somos centro examinador de los prestigiosos exámenes
              internacionales de inglés GESE – ISE de Trinity College London. A
              partir del 2019 hemos dado la bienvenida a PATH international
              examinations, a través de estos exámenes nuestros alumnos podrán
              certificar sus conocimientos de inglés a nivel internacional en
              nuestra institución.
            </p>
            <div className="about2">
              <p>
                Nuestros alumnos también podrán prepararse para rendir los
                exámenes internacionales de inglés de Cambridge
              </p>
            </div>
            <img className="img2" src={nosotros} alt="" />

            <p className="about3">
              Nuestros alumnos también podrán prepararse para rendir los
              exámenes internacionales de inglés de Cambridge: KET, PET, FCE,
              CAE, nosotros somos parte de la red de centros oficiales de
              preparación para dichos exámenes.<br></br>
              <br></br>
              Algo muy importante que queremos destacar es la importancia de
              nuestra metodología interactiva y dinámica, nuestras pantallas
              digitales interactivas hacen de nuestras clases sean más
              significativas para nuestros estudiantes.
            </p>
            <div className="firma">
              <h3>Victor Manuel Mendez Collantes</h3>
              <h4>
                Licenciado en educación: <br></br>
                especialidad inglés y francés.<br></br>
                Director de Oxford House.
              </h4>
            </div>
          </div>
          <img className="separadorBaby" src={separadorBaby} alt="" />
          <div className="babyContainer">
            <img className="babyImg1" src={nosotrosBaby1} alt="" />
            <span>
              <h2>Bilingüismo: cuanto antes, mejor...</h2>
              <p>
                Un error frecuente cuando hablamos de bilingüismo en edades
                tempranas es pensar que puede producir un retraso en el
                desarrollo del lenguaje de nuestros hijos. Un error que lleva a
                muchos padres a esperar a que el niño sea capaz de desenvolverse
                en un idioma antes de empezar con el segundo.
                <br></br>
                <br></br>
                Según la investigadora Dra. Patricia Khul, para aprender una
                lengua un bebé necesita exposición verbal repetida a formatos de
                este idioma, debe comprenderlo y compartirlo en comunidad. ALL
                English for babies® provee a sus profesores las estrategias y el
                material didáctico necesario para que los bebés puedan aprender
                inglés como segunda lengua, simultáneamente con su lengua
                materna.
              </p>
            </span>
            <span>
              <p className="blueText">
                Los niños son capaces de diferenciar dos lenguas desde el cuarto
                día de su vida
              </p>
              <p>
                Esta investigadora apunta a “empezar a oír el sonido de otra
                lengua, aprendiendo de una forma estructurada, usando una lengua
                concreta, en un momento en concreto, es una buenísima manera de
                iniciar a los niños y a los padres y un buen ejemplo de cómo
                empezar a criar un niño bilingüe”.
                <br></br>
                Se trata sobre todo de convertir la lengua en un juego,
                encontrar actividades y contar historias para atrapar el interés
                de los más pequeños, por ejemplo, a través de los cuentos. Al
                hablar una segunda lengua convertimos la competencia lingüística
                y comunicativa que ya poseemos y reconstruimos nuestra cultura
                del significar.
              </p>
            </span>
            <span>
              <h2 id="jardinMaternal">Jardín Maternal</h2>
              <p>
                En un espacio apropiado, cálido y seguro, nuestras docentes
                especializadas acompañan el desarrollo infantil, favoreciendo
                situaciones de interacción con los pequeños a partir del juego
                autónomo.
              </p>
            </span>
            <img src={nosotrosBaby2} alt="" />
            <span>
              <h2>Inglés en el Jardín Maternal</h2>
              <p>
                En nuestro Jardín Maternal organizamos encuentros de inglés a
                partir de las salitas Lactantes. Los niños vivencian su primer
                contacto con el idioma Inglés a través de juegos, canciones,
                rimas y fingerplays. De esta forma se inician en la segunda
                lengua simultáneamente con su lengua materna y se preparan para
                la escolaridad bilingüe.
                <br></br>
                Mediante la Educación Bilingüe en el Nivel Inicial se propicia
                en los niños desde los primeros meses de vida una atmósfera en
                la que el uso del Inglés se promueve desde las competencias
                comunicativas y se inicia el camino en un modo similar al
                proceso de la lengua materna, así la adquisición de la lengua
                extranjera se ve favorecida y enriquecida por el estímulo
                temprano en muchos aspectos:
                <br></br>
                <br></br>
                La actividad:
                <br></br>
                Porque el niño siente la necesidad de descubrir e investigar
                sobre los objetos de conocimiento, no sólo actuando directamente
                sobre ellos, sino que de acuerdo a su nivel pensamiento,
                estableciendo relaciones, generando hipótesis y comprobándolas.
                <br></br>
                La sociabilidad:
                <br></br>
                Porque confrontar puntos de vista diferentes es importante para
                el desarrollo del pensamiento lógico, cultivando la convivencia,
                la empatía y el cuidado del otro.
                <br></br>
                La libertad:
                <br></br>
                Porque el niño aprende a optar y a elegir para ser el día de
                mañana un adulto autónomo e independiente.
                <br></br>
                La creatividad:
                <br></br>
                Porque de la búsqueda de la originalidad surge el verdadero ser
                de cada persona.
                <br></br>
                La individualidad:
                <br></br>
                Porque no hay dos alumnos iguales. Si hay procesos de
                aprendizaje similares.
                <br></br>
                El juego:
                <br></br>
                Porque le permite construir, animarse, probar, preguntar, vencer
                miedos, valorar, darse permisos, darse tiempo y espacio, conocer
                y conocerse.
              </p>
            </span>
          </div>
          <div className="gruposContainer">
            <h2 className="gruposTitle">LOS GRUPOS:</h2>
            {babyGrupos.map(value => (
              <BlueTitleBox
                title={value.title}
                subtitle={value.subtitle}
                text={value.text}
              />
            ))}
            <p className="firma2">
              Equipo Directivo
              <br></br>
              Director General Lic. Victor Mendez
              <br></br>
              Sub Directora y Psicopedagoga: Prof. Karina Della Rossa.
            </p>
          </div>
        </div>
        <Footer />
        <DrawerButton />
      </Layout>
    </Fragment>
  );
}

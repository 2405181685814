export const babyGrupos = [
  {
    title: 'Babies',
    subtitle: '45 días a 8 meses',
    text:
      'Sala de lactarios: A cargo de dos docentes especializadas. Expresión corporal música, a cargo de las mismas docentes de la sala. En los primeros meses de vida se dan los avances más drásticos y sorprendentes. Los bebés aprenden a enfocar la vista, a estirarse, a explorar y también aprenden sobre las cosas que los rodean. El desarrollo cognitivo, o cerebral, se refiere al proceso de aprendizaje relacionado con la memoria, el lenguaje, el pensamiento y el razonamiento. Durante esta etapa, los bebés también forman lazos de afecto y confianza con sus padres y otras personas como parte de su desarrollo social y afectivo.',
  },

  {
    title: 'Kindergarten',
    subtitle: '24 meses (2 años)',
    text:
      'Sala de 2 años: A cargo de dos docentes especializadas. Música, Educación Física y la enseñanza de inglés en esta sala están a cargo de docentes especializadas. Se producen cambios relevantes vinculados con el juego simbólico, el lenguaje, el dibujo y control de esfínteres y la adquisición de normas. Es fundamental la interacción con el otro siempre con intervenciones de adultos responsables. Comienzan a mostrarse deseosos de aprender por sí mismos y se muestran mucho más independientes.',
  },

  {
    title: 'Toddlers I',
    subtitle: '8 meses a 16 meses',
    text:
      'Sala de deambuladores I: A cargo de dos docentes especializadas. Inglés, música y expresión corporal a cargo de las mismas docentes de sala. Es importante en esta etapa resignificar las actividades cotidianas articulando propuestas secuenciadas y los momentos de juego espontáneo, siempre basándose en el contacto afectivo y personal. El desarrollo físico se da en tanto la mayoría de los niños en esta edad comienza a caminar y descubrir nuevos horizontes.',
  },

  {
    title: 'Kids',
    subtitle: '3 años',
    text:
      'Sala de 3 años: A cargo de una docente especializada. Se complementa con profesores de Música, Educación Física. La enseñanza del inglés en esta sala está a cargo de una docente especializada. En esta etapa, los niños se van apropiando paulatinamente de una serie de pautas culturales. El tiempo y modo en que se dan estas apropiaciones están directamente relacionadas con el grupo familiar del niño, su contexto sociocultural y las características propias e él: comprensión y expresión del lenguaje oral, control de esfínteres, competencias grafo-plásticas y psicomotrices, musicales y de juego.',
  },

  {
    title: 'Toddlers II',
    subtitle: '16 meses a 24 meses',
    text:
      'Sala de deambuladores II: A cargo de dos docentes especializadas. Inglés, música y expresión corporal a cargo de las mismas docentes de sala. Se incluye en esta edad, el trabajo sobre desarrollo motriz, exploración del espacio y los objetos, contacto con lo sonoro y musical, y con otros niños. Aparecen distintos aspectos de su desarrollo emocional como aumento de sus temores, celos, vergüenza. De allí la importancia de acompañarlos desde el jardín durante todo el tiempo compartido.',
  },

  {
    title: 'Pre K-School',
    subtitle: '4 años',
    text:
      'Sala de 4 años: A cargo de una docente especializada. Se complementa con profesores de Música, Educación Física. La enseñanza del inglés en esta sala está a cargo de una docente especializada. En este período, los niños multiplican y potencian sus posibilidades motoras, cognitivas, lingüísticas, sociales y juegan con todas ellas. Los niños de 4 años están repletos de energía, son inagotables, el control progresivo del equilibrio y el dominio de los movimientos de su cuerpo, hacen que disfruten corriendo, saltando, trepando, arrastrándose, girando, bailando.',
  },
];

import React from 'react';

const BlueTitleBox = ({ title, subtitle, text }) => {
  return (
    <div className="blueTitleContainer">
      <h2>{title}</h2>
      <p className="subtitle">{subtitle}</p>
      <p>{text}</p>
    </div>
  );
};

export default BlueTitleBox;

module.exports = {
  siteTitle: 'Oxford House Ushuaia', // <title>
  manifestName: 'OxfordHouseUshuaia',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Viajes a Londres',
  subHeading:
    'Este programa permite a nuestros alumnos y público externo poder vivir una gran experiencia en una de las mejores capitales del mundo.',
  // social
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'facebook.com/oxfordhouseushuaia',
      url: 'https://facebook.com/oxfordhouseushuaia',
    },
    {
      icon: 'fa-envelope-o',
      name: 'oxfordhouseushuaia@gmail.com',
      url: 'mailto:oxfordhouseushuaia@gmail.com',
    },
    {
      icon: 'fa-whatsapp',
      name: 'Escribinos al WhatsApp',
      url: 'https://wa.me/5492901580824',
    },
  ],
  phone: '02901 42-4180',
  address: 'Magallanes 920, Ushuaia',
  maternalGarden: {
    phone: '02901 534012',
    address: 'Las Águilas 1316, Ushuaia',
    socialLinks: [
      {
        icon: 'fa-facebook',
        name: 'facebook.com/jardinmaternalbilingueoh',
        url: 'https://facebook.com/jardinmaternalbilingueoh',
      },
      {
        icon: 'fa-envelope-o',
        name: 'jardinmaternaloh2020@gmail.com',
        url: 'mailto:jardinmaternaloh2020@gmail.com',
      },
      {
        icon: 'fa-whatsapp',
        name: 'Escribinos al WhatsApp',
        url: 'https://wa.me/5492901534012',
      },
    ],
  },
};

import React from 'react';

const Banner = ({ title, subtitle, backgroundImage }) => {
  return (
    <div
      className="bannerComponentContainer"
      style={{
        backgroundImage,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="bannerText">
        <h1>{title}</h1>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
    </div>
  );
};

export default Banner;

import React from 'react';

const SelectorButton = ({ label, onSelectionPress, isSelected }) => {
  let buttonClass = 'baseButton';
  if (isSelected) {
    buttonClass += ' selectedButton';
  }

  return (
    <button className={buttonClass} onClick={onSelectionPress}>
      {label}
    </button>
  );
};

export default SelectorButton;
